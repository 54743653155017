import React from "react";

const Nadpis = (props) => (
   <div className={"nadpis d-flex align-items-center"}>
      <div className="container">
          {props.children}
      </div>
   </div>
);

export default Nadpis

